import StakingPool from './components/StakingPools';

function App() {
  return (
    <div className="App">
      <StakingPool />
    </div>
  );
}

export default App;
