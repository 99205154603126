import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
//import { Button, Input } from './ui';
import { poolData, TOKEN_ADDRESS, TOKEN_TICKER } from './poolData';

import TokenABI from '../abi/Token.json';
import TokenStakingPoolABI from '../abi/TokenStakingPool.json';
import './StakingPools.css'; // Ensure this is imported

const Button = ({ onClick, children, className, disabled }) => (
  <button onClick={onClick} className={`btn ${className}`} disabled={disabled}>
    {children}
  </button>
);

const ETHEREUM_MAINNET_CHAIN_ID = 42161; // Note: chainId is a number, not a string

const StakingPools = () => {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [contracts, setContracts] = useState({});
  const [account, setAccount] = useState('');
  const [balance, setBalance] = useState('0');
  const [tokenBalance, setTokenBalance] = useState('0');
  const [tokenFullBalance, setTokenFullBalance] = useState('0');
  const [network, setNetwork] = useState(null);
  const [isNetworkCorrect, setIsNetworkCorrect] = useState(true);
  const [stakedPools, setStakedPools] = useState([]);
  const [selectedPool, setSelectedPool] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmUnstake, setConfirmUnstake] = useState(false);
  const [confirmClaimRewards, setConfirmClaimRewards] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [timelockWarning, setTimelockWarning] = useState({ show: false, unlockTime: null });
  const [compoundMessage, setCompoundMessage] = useState(false);
  const [noRewardsWarning, setNoRewardsWarning] = useState({ show: false, message: '' });
  const [walletError, setWalletError] = useState('');

  const resetStates = () => {
    setProvider(null);
    setSigner(null);
    setContracts({});
    setAccount('');
    setBalance('0');
    setTokenBalance('0');
    setTokenFullBalance('0');
    setNetwork(null);
    setIsNetworkCorrect(true);
    setStakedPools([]);
    setSelectedPool(null);
    setTokenContract(null);
    setErrorMessage('');
    setConfirmUnstake(false);
    setConfirmClaimRewards(false);
    setPendingAction(null);
    setActionInProgress(false);
    setStatusMessage('');
    setSuccessMessage('');
    setTimelockWarning({ show: false, unlockTime: null });
    setCompoundMessage(false);
    setNoRewardsWarning({ show: false, message: '' });
    setWalletError('');
  };

  const shortenAddress = (address) => {
    return `Wallet:${address.slice(0, 7)}...${address.slice(-5)}`;
  };

  const calculateTimeLeft = (unlockTime) => {
    const now = new Date();
    const timeLeft = new Date(unlockTime) - now;
    if (timeLeft <= 0) {
      return "Already Unlocked!";
    }
    const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesLeft = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    return `${daysLeft}d ${hoursLeft}h ${minutesLeft}m`;
  };

  useEffect(() => {
    const init = async () => {
      if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.BrowserProvider(window.ethereum);
        setProvider(provider);

        const signer = await provider.getSigner();
        setSigner(signer);

        const contractInstances = {};
        for (const pool of poolData) {
          try {
            const contract = new ethers.Contract(pool.address, pool.abi, signer);
            contractInstances[pool.id] = contract;
          } catch (error) {
            console.error(`Failed to initialize contract for pool ${pool.id}`, error);
          }
        }
        setContracts(contractInstances);

        const tokenAddress = TOKEN_ADDRESS;
        const tokenContractInstance = new ethers.Contract(tokenAddress, TokenABI, signer);
        setTokenContract(tokenContractInstance);

        window.ethereum.on('accountsChanged', handleAccountsChanged);
        window.ethereum.on('chainChanged', handleChainChanged);

        await handleChainChanged();
      }
    };

    init();

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, []);

  const handleAccountsChanged = async (accounts) => {
    if (accounts.length > 0) {
      setAccount(accounts[0]);
      await updateNetwork();
      await updateBalance(accounts[0]);
      await updateStakedPools(accounts[0]);
    } else {
      resetStates();  // Reset states if no account is connected
    }
  };

  const handleChainChanged = async () => {
    await updateNetwork();
  };

  const connectWallet = async () => {
    console.log("Checking if Wallet is installed...");
  
    if (typeof window.ethereum === 'undefined') {
      console.log("Wallet is not installed");
      setWalletError((prev) => prev ? `${prev} Wallet is not installed. Please install it and try again.` : 'Wallet is not installed. Please install it and try again.');
      return;
    }
  
    console.log("Wallet is installed. Requesting account access...");
  
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      console.log("Wallet connected:", accounts);
      await handleAccountsChanged(accounts);
  
      // After connecting the wallet, let's check for the correct network
      await updateNetwork();
  
      // Handle balance or network errors
      if (!isNetworkCorrect) {
        const networkError = 'You are connected to the wrong network. Please switch to Ethereum mainnet.';
        setWalletError((prev) => prev ? `${prev} ${networkError}` : networkError);
      }
  
    } catch (error) {
      console.error("Error occurred during wallet connection:", error);
      let errorMsg = '';
      if (error.code === 4001) {
        console.log("User rejected the request.");
        errorMsg = 'User rejected the request. Please try again.';
      } else if (error.code === -32002) {
        console.log("Another wallet request is pending. Please check Wallet.");
        errorMsg = 'Another wallet request is pending. Please check Wallet.';
      } else {
        errorMsg = 'An unexpected error occurred. Please try again.';
      }
  
      // Append new errors to any existing error message
      setWalletError((prev) => prev ? `${prev} ${errorMsg}` : errorMsg);
    }
  
    console.log("End of connectWallet function");
  };
  

  const disconnectWallet = () => {
    resetStates();  // Reset all states upon disconnect
  };

  const updateNetwork = async () => {
    if (provider) {
      const network = await provider.getNetwork();
      setNetwork(network);
  
      const isCorrectNetwork = network.chainId == ETHEREUM_MAINNET_CHAIN_ID;
      setIsNetworkCorrect(isCorrectNetwork);
  
      if (!isCorrectNetwork) {
        setWalletError(
          `You are connected to the wrong network (${network.name}). Please switch to the Ethereum mainnet.`
        );
      } else {
        setWalletError(''); // Clear any previous error message
      }
  
      console.log(`Connected to network ${network.name} (${network.chainId})`);
    }
  };

  const updateBalance = async (address) => {
    if (provider && tokenContract) {
      const balance = await provider.getBalance(address);
      setBalance(parseFloat(ethers.formatEther(balance)).toFixed(6));

      const tokenBalance = await tokenContract.balanceOf(address);
      setTokenFullBalance(ethers.formatEther(tokenBalance));
      setTokenBalance(parseFloat(ethers.formatEther(tokenBalance)).toFixed(4));
    }
  };

  const updateStakedPools = async (address) => {
    if (contracts) {
      const stakes = [];
      for (const pool of poolData) {
        const contract = contracts[pool.id];
        if (contract) {
          try {
            const share = await contract.shares(address);
            console.log("share: ", share.amount);
            const ethReward = await contract.getUnpaid(address);

            const tokenStakingContract = new ethers.Contract(pool.tokenPoolAddress, TokenStakingPoolABI, signer);
            const tokenReward = await tokenStakingContract.rewardOf(address);

            const lockupPeriodInSeconds = pool.lockupPeriod * 24 * 60 * 60;
            const unlockTime = new Date((Number(share.stakedTime) + lockupPeriodInSeconds) * 1000);

            stakes.push({
              pool,
              staked: parseFloat(ethers.formatEther(share.amount)).toFixed(4),
              stakedFull: ethers.formatEther(share.amount),
              //ethRewards: parseFloat(ethers.formatEther(ethReward)).toFixed(10),
              ethRewards: ethers.formatEther(ethReward),
              tokenRewards: parseFloat(ethers.formatEther(tokenReward)).toFixed(4),
              tokenRewardsFull: ethers.formatEther(tokenReward),
              unlockTime,
            });
          } catch (error) {
            console.error(`Failed to fetch stake data for pool ${pool.id}`, error);
          }
        }
      }
      setStakedPools(stakes);
      console.log('Updated staked pools:', stakes);
    }
  };

  const handleStake = async (poolId, amount) => {
    setActionInProgress(true);
    setStatusMessage('Waiting for Wallet confirmation...');
    setWalletError('');
    try {
      if (!signer || !contracts[poolId] || !tokenContract || !amount || !isNetworkCorrect) {
        console.error('Staking failed: Missing required data');
        return;
      }

      const contractWithSigner = contracts[poolId].connect(signer);
      const poolAddress = await contractWithSigner.getAddress();

      console.log('Pool Address:', poolAddress);
      console.log('Amount to stake:', amount);

      const approveTx = await tokenContract.approve(poolAddress, ethers.parseEther(amount));
      console.log('Approval transaction:', approveTx.hash);
      await approveTx.wait();
      console.log('Approval confirmed');

      setStatusMessage('Staking in progress...');
      const stakeTx = await contractWithSigner.stake(ethers.parseEther(amount));
      console.log('Stake transaction:', stakeTx.hash);
      await stakeTx.wait();
      console.log('Stake confirmed');

      updateBalance(account);
      updateStakedPools(account);
      setSuccessMessage('Staking successful!');
    } catch (error) {
      if (error.code === 4001) {
        setWalletError('Transaction was rejected. Please approve the transaction in your wallet.');
      } else if (error.code === -32002) {
        setWalletError('Another wallet request is pending. Please check Wallet.');
      } else {
        console.error('Staking failed:', error);
        setWalletError('Staking failed. Please try again.');
      }
    } finally {
      setActionInProgress(false);
      setStatusMessage('');
      setSelectedPool(null);
    }
  };

  const handleUnstake = async (poolId, amount) => {
    console.log("handleUnstake", poolId, amount);
    const stakedPool = stakedPools.find(pool => pool.pool.id === poolId);
    if (stakedPool && stakedPool.unlockTime > new Date()) {
      setTimelockWarning({ show: true, unlockTime: stakedPool.unlockTime });
      return;
    }

    if (stakedPool && parseFloat(stakedPool.ethRewards) > 0) {
      setPendingAction(() => () => executeUnstake(poolId, amount));
      setConfirmUnstake(true);
      return;
    }
    console.log("Unstake: ", poolId, amount);
    executeUnstake(poolId, amount);
  };

  const executeUnstake = async (poolId, amount) => {
    setActionInProgress(true);
    setStatusMessage('Waiting for Wallet confirmation...');
    setWalletError('');
    try {
      if (contracts[poolId] && amount && isNetworkCorrect) {
        console.log("executeUnstake: ", poolId, amount);
        console.log("ethers.parseEther(amount): ", ethers.parseEther(amount.toString()));
        const contractWithSigner = contracts[poolId].connect(signer);
        //const tx = await contractWithSigner.unstake(ethers.parseEther(amount.toString()));
        const tx = await contractWithSigner.unstake(ethers.parseEther(amount.toString()));
        await tx.wait();
        updateBalance(account);
        updateStakedPools(account);
        setSuccessMessage('Unstaking successful!');
      }
    } catch (error) {
      if (error.code === 4001) {
        setWalletError('Transaction was rejected. Please approve the transaction in your wallet.');
      } else if (error.code === -32002) {
        setWalletError('Another wallet request is pending. Please check Wallet.');
      } else {
        console.error('Unstaking failed:', error);
        setWalletError('Unstaking failed. Please try again.');
      }
    } finally {
      setActionInProgress(false);
      setStatusMessage('');
      setConfirmUnstake(false);
    }
  };

  const handleClaimRewards = async (poolId, compound = false, minTokens = 0) => {
    console.log('handleClaimRewards' + poolId);
    const stakedPool = stakedPools.find(pool => pool.pool.id === poolId);
    console.log('stakedPool' + stakedPool);
    if (stakedPool) {
      if (parseFloat(stakedPool.ethRewards) <= 0) {
        setNoRewardsWarning({ show: true, message: 'No ETH rewards to claim.' });
        return;
      }
      setPendingAction(() => () => executeClaimRewards(poolId, compound, minTokens));
      setCompoundMessage(compound);
      setConfirmClaimRewards(true);
      return;
    }

    executeClaimRewards(poolId, compound, minTokens);
  };

  const executeClaimRewards = async (poolId, compound = false, minTokens = 0) => {
    setActionInProgress(true);
    setStatusMessage('Waiting for Wallet confirmation...');
    setWalletError('');
    try {
      if (contracts[poolId] && isNetworkCorrect) {
        const contractWithSigner = contracts[poolId].connect(signer);
        const tx = await contractWithSigner.claimReward(compound, minTokens);
        await tx.wait();
        updateBalance(account);
        updateStakedPools(account);
        setSuccessMessage(`Claiming ETH rewards successful${compound ? ' and compounding' : ''}!`);
      }
    } catch (error) {
      if (error.code === 4001) {
        setWalletError('Transaction was rejected. Please approve the transaction in your wallet.');
      } else if (error.code === -32002) {
        setWalletError('Another wallet request is pending. Please check Wallet.');
      } else {
        console.error('Claiming rewards failed:', error);
        setWalletError('Claiming rewards failed. Please try again.');
      }
    } finally {
      setActionInProgress(false);
      setStatusMessage('');
      setConfirmClaimRewards(false);
      setCompoundMessage(false);
    }
  };

  const handleClaimTokenRewards = async (tokenPoolAddress) => {
    const stakedPool = stakedPools.find(pool => pool.pool.tokenPoolAddress === tokenPoolAddress);
    if (stakedPool) {
      if (parseFloat(stakedPool.tokenRewardsFull) <= 0) {
        setNoRewardsWarning({ show: true, message: 'No token rewards to claim.' });
        return;
      }
    }

    setActionInProgress(true);
    setStatusMessage('Waiting for Wallet confirmation...');
    setWalletError('');
    try {
      if (!signer || !isNetworkCorrect) {
        console.error('Claiming token rewards failed: Missing required data');
        return;
      }

      const tokenStakingContract = new ethers.Contract(tokenPoolAddress, TokenStakingPoolABI, signer);
      const claimTx = await tokenStakingContract.claimRewards();
      await claimTx.wait();
      console.log('Token rewards claimed');
      updateStakedPools(account);
      setSuccessMessage('Claiming token rewards successful!');
    } catch (error) {
      if (error.code === 4001) {
        setWalletError('Transaction was rejected. Please approve the transaction in your wallet.');
      } else if (error.code === -32002) {
        setWalletError('Another wallet request is pending. Please check Wallet.');
      } else {
        console.error('Claiming token rewards failed:', error);
        setWalletError('Claiming token rewards failed. Please try again.');
      }
    } finally {
      setActionInProgress(false);
      setStatusMessage('');
    }
  };

const renderPools = () => {
  return poolData.map((pool) => (
    <div key={pool.id} className="pool">
      <h2>{pool.label}</h2>
      <p><span className="label">{TOKEN_TICKER} APR:</span> <span className="value">{pool.apr}%</span></p>
      <p><span className="label">ETH Revenue Share:</span> <span className="value">{pool.ethPerencent}%</span></p>
      <p><span className="label">Lockup Period:</span> <span className="value">{pool.lockupPeriod} days</span></p>
      <Button onClick={() => handlePoolClick(pool)} disabled={actionInProgress}>Stake Now</Button>
    </div>
  ));
};
  

  const handlePoolClick = async (pool) => {
    if (!account) {
      await connectWallet();
    }
    setSelectedPool(pool);
  };

  const renderStakedPools = () => {
    const isNewDisplay = false; // Toggle this flag to switch between the old and new display
  
    return stakedPools.map((stakedPool, index) => {
      const stakedAmount = parseFloat(stakedPool.staked);
      const isStaked = stakedAmount > 0;
  
      if (isNewDisplay) {
        // New Display Format
        return (
          <div key={index} className="staked-pool">
            <div className="staked-pool-info">
              <h3>{stakedPool.pool.label} - {stakedPool.pool.apr}% APR</h3>
              <p><span className="label">Staked:</span> <span className="value">{isStaked ? `${stakedPool.staked} ${TOKEN_TICKER}` : `0.0000 ${TOKEN_TICKER}`}</span></p>
              <p><span className="label">Token Rewards:</span> <span className="value">{isStaked ? `${stakedPool.tokenRewards} ${TOKEN_TICKER}` : 'N/A'}</span></p>
              <p><span className="label">ETH Rewards:</span> <span className="value">{isStaked ? `${stakedPool.ethRewards} ETH` : 'N/A'}</span></p>
              <p><span className="label">Unlock At:</span> <span className="value">{isStaked ? stakedPool.unlockTime.toLocaleString() : 'N/A'}</span></p>
              <p><span className="label">Unlock In:</span> <span className="value">{isStaked ? calculateTimeLeft(stakedPool.unlockTime) : 'N/A'}</span></p>
            </div>
            <div className="staked-pool-actions">
              <Button
                onClick={() => handleClaimTokenRewards(stakedPool.pool.tokenPoolAddress)}
                disabled={!isStaked || actionInProgress}
                className="claim-rewards"
              >
                Claim Tokens
              </Button>
              <Button
                onClick={() => handleUnstake(stakedPool.pool.id, stakedPool.stakedFull)}
                disabled={!isStaked || actionInProgress}
              >
                Unstake
              </Button>
              <Button
                onClick={() => handleClaimRewards(stakedPool.pool.id)}
                disabled={!isStaked || actionInProgress}
                className="claim-rewards"
              >
                Claim ETH
              </Button>
              <Button
                onClick={() => handleClaimRewards(stakedPool.pool.id, true)}
                disabled={!isStaked || actionInProgress}
              >
                Compound ETH
              </Button>
            </div>
          </div>
        );
      } else {
        // Original Display Format
        return (
          <div key={index} className="staked-pool">
            <div className="staked-pool-info">
              <h3>{stakedPool.pool.label} - {stakedPool.pool.apr}% APR</h3>
              {isStaked ? (
                <>
                  <p><span className="label">Staked:</span> <span className="value">{`${stakedPool.staked} ${TOKEN_TICKER}`}</span></p>
                  <p><span className="label">Token Rewards:</span> <span className="value">{`${stakedPool.tokenRewards} ${TOKEN_TICKER}`}</span></p>
                  <p><span className="label">ETH Rewards:</span> <span className="value">{`${stakedPool.ethRewards} ETH`}</span></p>
                  <p><span className="label">Unlock At:</span> <span className="value">{stakedPool.unlockTime.toLocaleString()}</span></p>
                  <p><span className="label">Unlock In:</span> <span className="value">{calculateTimeLeft(stakedPool.unlockTime)}</span></p>
                </>
              ) : (
                <>
                  <p>You don't have any staked {TOKEN_TICKER} in this pool.</p>
                  <p>Please stake {TOKEN_TICKER} to earn rewards.</p>
                </>
              )}
            </div>
            <div className="staked-pool-actions">
              <Button
                onClick={() => handleClaimTokenRewards(stakedPool.pool.tokenPoolAddress)}
                disabled={!isStaked || actionInProgress}
                className="claim-rewards"
              >
                Claim Tokens
              </Button>
              <Button
                onClick={() => handleUnstake(stakedPool.pool.id, stakedPool.stakedFull)}
                disabled={!isStaked || actionInProgress}
              >
                Unstake
              </Button>
              <Button
                onClick={() => handleClaimRewards(stakedPool.pool.id)}
                disabled={!isStaked || actionInProgress}
                className="claim-rewards"
              >
                Claim ETH
              </Button>
              <Button
                onClick={() => handleClaimRewards(stakedPool.pool.id, true)}
                disabled={!isStaked || actionInProgress}
              >
                Compound ETH
              </Button>
            </div>
          </div>
        );
      }
    });
  };
  

  

  const handleMaxClick = () => {
    if (selectedPool) {
      const stakeInput = document.getElementById('stake-amount-input');
      stakeInput.value = tokenFullBalance;
      selectedPool.stakeAmount = tokenFullBalance;
    }
  };

  const renderStakeModal = () => {
    if (!selectedPool) return null;

    const stakedPool = stakedPools.find(pool => pool.pool.id === selectedPool.id);
    const isAlreadyStaked = stakedPool && parseFloat(stakedPool.staked) > 0;

    return (
      <div className="modal" onClick={() => setSelectedPool(null)}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <h2>Stake for {selectedPool.label}</h2>
          <input
            type="number"
            placeholder="Amount to stake"
            onChange={(e) => selectedPool.stakeAmount = e.target.value}
            className="input-field"
            id="stake-amount-input"
            disabled={!isNetworkCorrect}
          />
          <div className="balance-info">
            Balance: {tokenFullBalance} {TOKEN_TICKER} <span className="max-link" onClick={handleMaxClick}>Max</span>
          </div>
          {isAlreadyStaked && (
            <p className="notice-message">
              You are already staking in this pool. <br />Staking more will relock and restart the lock period.
            </p>
          )}
          <div className="modal-buttons">
            <Button onClick={() => handleStake(selectedPool.id, selectedPool.stakeAmount)} disabled={!isNetworkCorrect}>Stake</Button>
            <Button onClick={() => setSelectedPool(null)}>Close</Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`staking-pools ${actionInProgress ? 'greyed-out' : ''}`}>
      <div className="header">
        <div className="header-logo">
          <img src="logo.png" alt="Logo" className="logo-image" />
          <h2>Cortensor Staking</h2>
        </div>
        <div className="wallet-info">
          {account ? (
            <>
              <p className="wallet-detail">{shortenAddress(account)}</p>
              <p className="wallet-detail">{balance} ETH</p>
              <p className="wallet-detail">{tokenBalance} {TOKEN_TICKER}</p>
              <Button onClick={disconnectWallet} className="disconnect-btn">Disconnect Wallet</Button>
            </>
          ) : (
            <Button onClick={connectWallet}>Connect Wallet</Button>
          )}
        </div>
      </div>
      <div className="pools-container">
        {renderPools()}
      </div>
      {account && (
        <div className="staked-pools-section">
          {!isNetworkCorrect && <p style={{ color: 'red' }}>Please connect to the Ethereum mainnet.</p>}
          <h2>My Stakes & Rewards</h2>
          <div className="staked-pools-container">
            {renderStakedPools()}
          </div>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
      )}
      {renderStakeModal()}
      {confirmUnstake && (
        <div className="modal" onClick={() => setConfirmUnstake(false)}>
          <div className="modal-content same-width" onClick={(e) => e.stopPropagation()}>
            <h2>Warning</h2>
            <p>You have remaining ETH rewards. Unstaking now will result in losing those ETH rewards.<br /> Do you want to proceed?</p>
            <div className="modal-buttons">
              <Button onClick={() => { pendingAction(); setConfirmUnstake(false); }}>Withdraw Token</Button>
              <Button onClick={() => setConfirmUnstake(false)}>Cancel</Button>
            </div>
          </div>
        </div>
      )}
      {timelockWarning.show && (
        <div className="modal" onClick={() => setTimelockWarning({ show: false, unlockTime: null })}>
          <div className="modal-content same-width" onClick={(e) => e.stopPropagation()}>
            <h2>Warning</h2>
            <p>You cannot unstake until the timelock period has expired.</p>
            <p>Unlock At: {timelockWarning.unlockTime.toLocaleString()}<br />
              Unlock In: {calculateTimeLeft(timelockWarning.unlockTime)} left</p>
          </div>
        </div>
      )}
      {confirmClaimRewards && (
        <div className="modal" onClick={() => setConfirmClaimRewards(false)}>
          <div className="modal-content same-width" onClick={(e) => e.stopPropagation()}>
            <h2>{compoundMessage ? 'Notice - Compound & Relock' : 'Notice - Claim & Relock'}</h2>
            {compoundMessage ? (
              <p>Compounding ETH rewards will swap ETH for tokens and stake them, restarting the lock period.<br /><br /> Do you want to proceed?</p>
            ) : (
              <p>Claiming ETH rewards will lock your tokens again until the next unlock period.<br /><br /> Do you want to proceed?</p>
            )}
            <div className="modal-buttons">
              <Button onClick={() => { pendingAction(); setConfirmClaimRewards(false); }}>{compoundMessage ? 'Compound ETH' : 'Claim ETH'}</Button>
              <Button onClick={() => setConfirmClaimRewards(false)}>Cancel</Button>
            </div>
          </div>
        </div>
      )}
      {noRewardsWarning.show && (
        <div className="modal" onClick={() => setNoRewardsWarning({ show: false, message: '' })}>
          <div className="modal-content same-width" onClick={(e) => e.stopPropagation()}>
            <h2>No Rewards</h2>
            <p>{noRewardsWarning.message}</p>
          </div>
        </div>
      )}
      {actionInProgress && (
        <div className="overlay-modal">
          <div className="modal-content same-width">
            <h2>Action in Progress</h2>
            <div className="spinner"></div>
            <p>{statusMessage}</p>
          </div>
        </div>
      )}
      {successMessage && (
        <div className="overlay-modal" onClick={() => setSuccessMessage('')}>
          <div className="modal-content same-width" onClick={(e) => e.stopPropagation()}>
            <h2>Success</h2>
            <p>{successMessage}</p>
            <Button onClick={() => { setSuccessMessage(''); setSelectedPool(null); }}>Close</Button>
          </div>
        </div>
      )}
      {walletError && (
        <div className="overlay-modal" onClick={() => setWalletError('')}>
          <div className="modal-content same-width" onClick={(e) => e.stopPropagation()}>
            <h2>Error</h2>
            <p>{walletError}</p>
            <Button onClick={() => setWalletError('')}>Close</Button>
          </div>
        </div>
      )}
      <footer className="footer">
        <div>
          <a href="https://www.cortensor.network">Home</a>
          <a href="https://docs.cortensor.network">Documentation</a>
          <a href="https://docs.cortensor.network/legal/privacy-policy">Privacy Policy</a>
          <a href="https://docs.cortensor.network/legal/terms-of-use">Terms of Use</a>
        </div>
        <div><br/></div>
        <div>
          Cortensor © 2024. All Rights Reserved.
        </div>
        <div>
          Unleashing the Future of AI Together.
        </div>
      </footer>
    </div>
  );
};

export default StakingPools;
