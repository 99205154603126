import StakingPoolABI from '../abi/StakingPoolABI.json';

export const poolData = [
  {
    id: 'pool1',
    label: '28 Days',
    apr: 15,
    ethPerencent: 10,
    lockupPeriod: 28,
    address: '0x677EfEd45F26E62Bdf866eC7D64A3f11435c5C39', // Replace with actual contract address
    tokenPoolAddress: '0xd656e4a3F8B0326fb823fAC4aF40BaBC826045eb', // Replace with actual token pool address
    abi: StakingPoolABI
  },
  {
    id: 'pool2',
    label: '56 Days',
    apr: 30,
    ethPerencent: 30,
    lockupPeriod: 56,
    address: '0x8FfFF4cf730CA2535C000F8DFc4eAC0Db96114D6', // Replace with actual contract address
    tokenPoolAddress: '0x1F3F3a604450CE7dC39A2Fd0d108FB8e2c1f2eCA', // Replace with actual token pool address
    abi: StakingPoolABI
  },
  {
    id: 'pool3',
    label: '84 Days',
    apr: 60,
    ethPerencent: 60,
    lockupPeriod: 84,
    address: '0x7d08792175385dCC7Ef18F554869B975d5B6D13a', // Replace with actual contract address
    tokenPoolAddress: '0x82Fc8d9b9041190a3f63C6090547a81362B9C0E7', // Replace with actual token pool address
    abi: StakingPoolABI
  }
];

export const TOKEN_ADDRESS = '0x62A621761dCcad0Ce132AF02b8297A33E88F47ed';
export const TOKEN_TICKER = "$COR"